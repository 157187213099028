<template>
  <div class="container">
    <Title title="NDIS Registered Providers" />
    <div class="content">
      <p>
        ProHealth EP is a registered provider with the National Disability
        Insurance Scheme (NDIS). We can assist you with exercise physiology
        services under the <b>Improved Daily Living</b>,
        <b>Health and Wellbeing</b>,
        <b>Therapeutic Supports sections and Core Supports</b> for those with a
        permanent disability, where the disability impacts the ability to
        perform activities of daily living. This means we can assist you whether
        you are plan managed, self-managed or NDIA managed.
      </p>
      <p>
        Our exercise physiologists work together with your health care team to
        provide you with the best possible treatment plan. We can assist you
        with the following treatment options:
      </p>
      <ul>
        <li>Hydrotherapy</li>
        <li>Home-based exercise therapy</li>
        <li>Gym-based exercise therapy</li>
        <li>Group-based exercise therapy</li>
        <li>Clinical Pilates</li>
      </ul>
      <p>
        Regular exercises has been proven to provide many benefits including:
      </p>
      <ul>
        <li>Improving muscular and bone strength</li>
        <li>Improving postural control</li>
        <li>Improving your ability to complete daily tasks</li>
        <li>Improving quality of life</li>
        <li>Reducing joint pain</li>
        <li>Improving balance and reducing risk of falls</li>
        <li>Reducing risk of mental health concerns</li>
        <li>
          Reducing risk of co-morbidities such as heart disease and diabetes
        </li>
      </ul>
      <p>
        All exercise physiology treatment costs will be funded by the NDIS when
        the NDIA plan has been approved under the Health and Wellbeing support
        category; and/or Therapeutic Supports; and/or Improved Daily Living;
        and/or Core Supports.
      </p>
      <h2>How are we continuing to provide services during COVID-19?</h2>
      <p>
        As we have all seen, the sudden outbreak of the Coronavirus (COVID-19)
        has impacted the way we continue to live including our allied health
        providers.
      </p>
      <p>
        To ensure we continue providing good hygiene and reduce the risk of
        infection we will be exercising the following:
      </p>
      <ul>
        <li>Wearing surgical masks</li>
        <li>Wearing gloves</li>
        <li>Cleaning equipment before and after each client</li>
        <li>Washing hands before and after each client</li>
        <li>Social distancing where possible</li>
      </ul>
      <p>
        We will be continuing Telehealth services for those who would prefer to
        do so.
      </p>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

h2 {
  color: #009cdf;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
}

p {
  line-height: 2em;
}
</style>
